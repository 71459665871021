<template> 
  <div>       
    <div v-if="isShowInvalid">
      <p class="text-gray-500 mb-5 text-sm font-bold">Block Details</p>
      <div class="p-3 bg-yellow-100 text-yellow-700">Block is not available in {{ networkName }}</div>
    </div>
    <div v-else> 
    <div>
      <p class="text-gray-500 mb-5 text-sm font-bold">
        Block Details
      </p>   
      <div class="filter shadow-xl border border-gray-50 p-5 mb-15">
        <div class="txn-div">
          <div>
            <div>Block Height</div>
            <div>{{blockInfo.height}}</div>
          </div>
          <div>
            <div>Timestamp</div>
            <div>{{blockInfo.timestamp}}</div>
          </div>
          <div>
            <div>Validator</div>
            <div class="break-all flex items-center">  
              {{blockInfo.validator}}
            </div>
          </div>
          <div>
            <div>Hash</div>
            <div class="break-all flex items-center">{{blockInfo.hash}}</div>
          </div>
          <div>
            <div>Difficulty</div>
            <div>{{blockInfo.difficulty}}</div>
          </div>
          <div>
            <div>No. of Transaction</div>
            <div>{{blockInfo.numTransactions}}</div>
          </div>
          <div>
            <div>Total Fees</div>
            <div class="font-bold">{{ TransactionUtils.convertToExactNativeAmount(blockInfo.totalFee) }}<img src="@/assets/img/icon-xpx.svg" class="inline-block ml-2" style="top:-3 width:14px;" />
            <span class="ml-2">{{ nativeTokenNamespace }}</span> </div>
          </div>
          <div>
            <div>Fee Multiplier</div>
            <div>{{blockInfo.feeMultiplier}}</div>
          </div>
        </div>
      </div>
      <div v-if="blockInfo.numTransactions > 0">
        <p class="text-gray-500 mb-5 text-sm font-bold">
        Transactions
        </p>
        <div class="filter shadow-xl border border-gray-50 p-5 mb-15">
          <TransactionComponent  :blockHeight="blockInfo.height" />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref, watch } from "vue";
import { BlockUtils } from "@/util/blockUtil"
import { AppState } from '@/state/appState';
import { networkState } from '@/state/networkState';
import TransactionComponent from '@/modules/block/components/TransactionComponent.vue';
import { Helper } from "@/util/typeHelper";
import { TransactionUtils } from '@/models/util/transactionUtils';

export default {
  components: { TransactionComponent },
  name: 'ViewBlock',
  props: {
    blockHeight: Number
  },
  setup(p){
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const blockInfo = ref([]);
    const isShowInvalid = ref(false);
    const nativeTokenNamespace = AppState.nativeToken.label;
    const loadBlock = async() =>{
      const block = await BlockUtils.getBlockByHeight(p.blockHeight);
        if(!AppState.isReady){
            setTimeout(loadBlock, 1000);
        }
    
        if(block!=false){
          blockInfo.value = block; 
          isShowInvalid.value = false;
          return;
        }else{
          isShowInvalid.value = true;
        }
    };  
    loadBlock();

    const networkName = computed(() => {
      return networkState.chainNetworkName;
    });

    emitter.on('CHANGE_NETWORK', payload => {
      if(payload){
        loadBlock();
      }
    });

    const currencyDivisibility = computed(() => {
      return AppState.nativeToken.divisibility;
    })

    return {
      blockInfo,
      networkName,
      isShowInvalid,
      nativeTokenNamespace,
      Helper,
      currencyDivisibility,
      TransactionUtils
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.txn-div{
  @apply text-gray-800 text-xs;
  > div{
    @apply flex items-center border-b border-gray-100 py-4;

    > div:first-child{
      @apply w-56 text-xs pl-4 font-bold;
    }

    > div:nth-child(2){
      @apply text-xs w-full;
    }

    > div:last-child{
      @apply border-none;
    }
  }
}
</style>